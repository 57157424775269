import * as React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  useMediaQuery,
  MenuItem,
  Chip,
  Autocomplete,
  Button,
  Divider,
  Modal,
  FilledInput,
  InputAdornment,
  IconButton,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
  Alert,
  Popover,
  AlertTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UndoIcon from '@mui/icons-material/Undo';
import DownloadIcon from '@mui/icons-material/Download';
import Visibility from "@mui/icons-material/Visibility";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AddIcon from "@mui/icons-material/Add";
import { Delete } from "@mui/icons-material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useDispatch, useSelector } from "react-redux";
import { clearToken } from "../redux/features/slice/authSlice";
import { useUser } from "../provider/UserProvider";
import { DataGrid } from "@mui/x-data-grid";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Subscription } from "./pagamento/Subscription";
import { MeasurementSingleLineChart } from "./MeasurementLineChart";
import { BiaMetricsSection } from "./metricsSection/BiaMetricsSection";
import { SkinfoldMetricsSection } from "./metricsSection/SkinfoldMetricsSection";
import { CircumferenceMetricsSection } from "./metricsSection/CircumferenceMetricsSection";
import {
  useGetVisitsQuery,
  useGetMealPlansQuery,
  usePostNewPasswordMutation,
  usePutNutritionistMutation,
  useDeleteVisitsMutation,
  useDeleteMealPlansMutation,
  useGetNutritionistSubscriptionInfoQuery,
  usePutReferralsMutation,
  useLazyCheckAvailableReferrerDiscountQuery,
  usePutMealPlansMutation,
  usePostCloneMealPlansMutation,
  api
} from "../redux/state/api";
import { useForm } from "react-hook-form";
import FileUploadButton from "./UploadButton";
import { fetchUserData, clearUserState } from "../redux/features/slice/userSlice"
import queryString from 'query-string';
import axios from "axios"
import { calculate_eer, calculate_bmi, calculate_tdee } from "../utils/measurements_metrics";


export const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: "80%",
    width: "100%",
    backgroundColor: "black",
  },
});



export const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
    marginRight: theme.spacing(1),
    color: "black",
    "&.Mui-selected": {
      fontWeight: "700",
      color: "black",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "black",
    },
  })
);

export function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function ProfileTabs() {


  const { handleSubmit, formState: { isSubmitting } } = useForm();
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const userInformation = useUser();
  const location = useLocation();

  const queryParams = queryString.parse(location.search);

  const [postNewPassword, { isLoading, isError }] =
    usePostNewPasswordMutation();
  const [putNutritionist, { isLoading: isPutNutritionistLoading, isError: isPutNutritionistError }] =
    usePutNutritionistMutation();
  const [putReferral, { isLoading: isPutReferralLoading, isError: isPutReferalError }] =
    usePutReferralsMutation();
  const [checkAvailableReferrerDiscount] = useLazyCheckAvailableReferrerDiscountQuery();

  const id = useSelector((state) => state.auth.userId);
  const accessToken = useSelector((state) => state.auth.userToken);
  const { data: subscriptionInfo, isLoading: isSubscriptionInfoLoading, isError: isSubscriptionInfoError } = useGetNutritionistSubscriptionInfoQuery({ id: id });
  const [userChange, setUserChange] = React.useState({
    name: userInformation.name,
    surname: userInformation.surname,
    email: userInformation.email,
    gender: userInformation.gender,
    identity_card: userInformation.identity_card,
    cv: userInformation.cv,
    order: userInformation.order,
    order_id: userInformation.order_id,
    competence_areas: userInformation.competence_areas,
    p_iva: userInformation.p_iva,
    tax_code: userInformation.tax_code,
    study_field: userInformation.study_field,
    profile_picture: userInformation.profile_picture,
    addresses: userInformation.addresses.length > 0 ? userInformation.addresses : [{ street: "", postal_code: "", city: "", country: "" }],
    phone: userInformation.phone,
    unique_code: userInformation.unique_code,
    pec: userInformation.pec,
  });

  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [studi, setStudi] = React.useState(
    userChange.study_field != null ? userChange.study_field : ""
  );
  const [order, setOrder] = React.useState(
    userChange.order != null ? userChange.order : ""
  );
  const [orderId, setOrderId] = React.useState(
    userChange.order_id != null ? userChange.order_id : ""
  );
  const [pec, setPec] = React.useState(
    userChange.pec != null ? userChange.pec : ""
  );
  const [sesso, setSesso] = React.useState(
    userChange.gender != null ? userChange.gender : ""
  );
  const [open, setOpen] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [newPasswordData, setNewPasswordData] = React.useState({});
  const [nutritionistHasReferredId, setNutritionistHasReferredId] = React.useState();

  const dispatch = useDispatch();

  const handleChangeOldPass = (event) => {
    setNewPasswordData({
      ...newPasswordData,
      old_password: event.target.value,
    });
  };

  const handleChangeNewPass = (event) => {
    setNewPasswordData({
      ...newPasswordData,
      new_password: event.target.value,
      new_password_confirm: event.target.value,
    });
  };
  const [isPasswordValid, setIsPasswordValid] = React.useState(true);
  const handleChangePassword = async () => {
    if (newPasswordData.new_password.length < 8) {
      setIsPasswordValid(false);
      return;
    }
    setIsPasswordValid(true);
    await postNewPassword(newPasswordData);
    setOpen(false);
  };

  const handleAutocompleteChange = (event, value) => {
    setUserChange({ ...userChange, competence_areas: value });
  };

  const handleLogout = () => {
    dispatch(clearToken());
    dispatch(clearUserState());
    dispatch(api.util.resetApiState());
    navigate("/login");
  };


  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeSelectStudi = (event) => {
    setStudi(event.target.value);
  };

  const handleChangeStudies = (event) => {
    handleChangeSelectStudi(event);
    setUserChange({ ...userChange, study_field: event.target.value });
  };

  const handleChangeSelectOrder = (event) => {
    setOrder(event.target.value);
  };

  const handleChangeOrder = (event) => {
    handleChangeSelectOrder(event);
    setUserChange({ ...userChange, order: event.target.value });
  };

  const handleChangeOrderId = (event) => {
    setOrderId(event.target.value);
    setUserChange({ ...userChange, order_id: event.target.value });
  };

  const handleChangePec = (event) => {
    setPec(event.target.value);
    setUserChange({ ...userChange, pec: event.target.value });
  };

  const handleChangeSelectSesso = (event) => {
    setSesso(event.target.value);
  };

  const handleChangeSesso = (event) => {
    handleChangeSelectSesso(event);
    setUserChange({ ...userChange, gender: event.target.value });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleIdentityCardUpload = async (file) => {
    setUserChange({ ...userChange, identity_card: file });
  }

  const handleIdentityCardUploadRemoval = async () => {
    setUserChange({ ...userChange, identity_card: null });
  }

  const handleCvUpload = async (file) => {
    setUserChange({ ...userChange, cv: file });
  }

  const handleCvUploadRemoval = async () => {
    setUserChange({ ...userChange, cv: null });
  }

  const handleSwitchTab = (tabId) => {
    setValue(tabId)
  }

  React.useEffect(() => {
    const updateNutritionistSubscription = async () => {
      const today = new Date();
      let newExpirationDate;
      if (queryParams.plan === "monthly") {
        newExpirationDate = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());
      } else if (queryParams.plan === "quarterly") {
        newExpirationDate = new Date(today.getFullYear(), today.getMonth() + 3, today.getDate());
      } else if (queryParams.plan === "annual") {
        newExpirationDate = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());
      }
      await putNutritionist(
        {
          data:
          {
            subscription_plan: queryParams.plan,
            subscription_plan_expiration: newExpirationDate,
          },
          id: id
        }
      );
    }

    const updateNutritionistReferral = async () => {
      await putNutritionist(
        {
          data:
          {
            referred: true
          },
          id: id
        }
      );
      await putReferral({
        data: {
          referral_status: "accepted",
          nutritionist_referee_id: id,
        }
      })
    }
    const consumeReferral = async () => {
      await putReferral({
        data: {
          referral_status: "consumed",
          id: nutritionistHasReferredId,
          nutritionist_referrer_id: id,
        }
      })
    }

    const checkAvailableReferral = async () => {
      const { data: res } = await checkAvailableReferrerDiscount();
      setNutritionistHasReferredId(res.nutritionistHasReferredId);
    }
    checkAvailableReferral();

    if (queryParams.plan) {
      updateNutritionistSubscription();
    }
    if (queryParams.discount) {
      updateNutritionistReferral();
    }
    if (queryParams.referrer && nutritionistHasReferredId) {
      consumeReferral();
    }
  }, [queryParams.discount, queryParams.plan, queryParams.referrer, putNutritionist, putReferral, checkAvailableReferrerDiscount, id, nutritionistHasReferredId]);

  const onSubmit = async () => {
    try {
      let formData = new FormData()
      let formDataObj = {
        //Dati professionista
        ...userChange.order && { "order": userChange.order },
        ...userChange.order_id && { "order_id": userChange.order_id },
        ...userChange.competence_areas && { "competence_areas": userChange.competence_areas },
        ...userChange.p_iva && { "p_iva": userChange.p_iva },
        ...userChange.study_field && { "study_field": userChange.study_field },
        ...userChange.pec && { "pec": userChange.pec },
        // ...userChange.unique_code  && { "unique_code": userChange.unique_code },
        //Dati utente
        ...userChange.name && { "name": userChange.name },
        ...userChange.surname && { "surname": userChange.surname },
        // Email editing disabled
        // ...userChange.email  && { "email": userChange.email },
        ...userChange.gender && { "gender": userChange.gender },
        ...userChange.study_field && { "study_field": userChange.study_field },
        ...userChange.phone && { "phone": userChange.phone },
        ...userChange.tax_code && { "tax_code": userChange.tax_code },
        ...userChange.addresses && {
          "addresses": userChange.addresses.map(({ id, created_at, updated_at, ...rest }) => rest).filter(obj =>
            Object.values(obj).every(value => value !== "")
          )
        },
      }
      for (const key in formDataObj) {
        if (formDataObj.hasOwnProperty(key)) {
          if (Array.isArray(formDataObj[key])) {
            formData.append(key, JSON.stringify(formDataObj[key]));
          } else {
            formData.append(key, formDataObj[key]);
          }
        }
      }
      if (userChange.identity_card) {
        formData.append("identity_card", userChange.identity_card)
      }
      if (userChange.cv) {
        formData.append("cv", userChange.cv)
      }
      for (const [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }
      const res = await putNutritionist({ data: formData, id: id });
      console.log(res)
      dispatch(fetchUserData({ userId: id, accessToken }));
    } catch (error) {
      console.error("Error occurred while creating recipe:", error);
    }
  };

  const handleAddAddress = () => {
    setUserChange((prev) => ({
      ...prev,
      addresses: [...prev.addresses, { street: "", postal_code: "", city: "", country: "" }],
    }));
  };
  const handleRemoveAddress = (index) => {
    setUserChange((prev) => ({
      ...prev,
      addresses: prev.addresses.filter((_, i) => i !== index),
    }));
  };
  const handleAddressInputChange = (index, field, value) => {
    const updatedAddresses = [...userChange.addresses];
    updatedAddresses[index][field] = value;
    setUserChange((prev) => ({ ...prev, addresses: updatedAddresses }));
  };

  if (isLoading || isPutNutritionistLoading || isSubscriptionInfoLoading || isPutReferralLoading) {
    return <div class="loader-container">
      <div class="loader"></div>
    </div>
      ;
  }

  if (isError || isPutNutritionistError || isSubscriptionInfoError || isPutReferalError) {
    return <p>Error</p>;
  }

  return (
    <Box sx={{ width: "100%", marginTop: "12px", marginLeft: "-46px" }}>
      <Box>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
          sx={{ marginLeft: "16px" }}
        >
          <StyledTab label="Dati professionista" {...a11yProps(0)} />
          <StyledTab label="Dati utente" {...a11yProps(1)} />
          <StyledTab label="Il tuo piano" {...a11yProps(2)} />
          <StyledTab label="Log out/Cancella" {...a11yProps(3)} />
        </StyledTabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <FormControl variant="filled" sx={{ m: 1, minWidth: 350, }}>
          <InputLabel id="order-select-label" color="secondary">
            Ordine
          </InputLabel>
          <Select
            labelId="order-select-label"
            id="selectOrder"
            label="Ordine"
            value={order}
            onChange={handleChangeOrder}
            color="secondary"
          >
            <MenuItem value={"Biologi"}>Biologi</MenuItem>
            <MenuItem value={"Dietisti"}>Dietisti</MenuItem>
            <MenuItem value={"Medici"}>Medici</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          variant="standard"
          sx={{ m: 1, minWidth: 350 }}
        >
          <TextField
            id="numeroIscrizione"
            label="Numero di iscrizione all'albo"
            variant="filled"
            color="secondary"
            value={orderId}
            onChange={handleChangeOrderId}
          />
        </FormControl>
        {/* <FormControl variant="filled" sx={{ m: 1, minWidth: 350 }}>
          <InputLabel id="studi-select-label" color="secondary">
            INDIRIZZO DI STUDI
          </InputLabel>
          <Select
            labelId="studi-select-label"
            id="selectStudi"
            label="INDIRIZZO DI STUDI"
            value={studi}
            onChange={handleChangeStudies}
            color="secondary"
          >
            <MenuItem value={"a"}>LOREM IPSUM</MenuItem>
            <MenuItem value={"b"}>DOLOR SIT</MenuItem>
            <MenuItem value={"c"}>AMET CONSE</MenuItem>
          </Select>
        </FormControl> */}
        <FormControl variant="standard" sx={{ m: 1, minWidth: 350 }}>
          <TextField
            id="partitaIva"
            label="Partita iva"
            value={userChange.p_iva != null ? userChange.p_iva : ""}
            variant="filled"
            color="secondary"
            onChange={(event) =>
              setUserChange({ ...userChange, p_iva: event.target.value })
            }
          />
        </FormControl>
        {/* <FormControl variant="standard" sx={{ m: 1, minWidth: 350 }}>
          <TextField
            id="codiceUni"
            label="CODICE UNIVOCO"
            value={userChange.unique_code != null ? userChange.unique_code : ""}
            variant="filled"
            color="secondary"
            onChange={(event) =>
              setUserChange({ ...userChange, unique_code: event.target.value })
            }
          />
        </FormControl> */}
        <FormControl
          variant="standard"
          sx={{ m: 1, minWidth: 718 }}
        >
          <Autocomplete
            value={
              userChange.competence_areas != null
                ? userChange.competence_areas
                : []
            }
            multiple
            id="areaComp"
            options={areaDiCompetenza.map((option) => option.area)}
            freeSolo
            onChange={handleAutocompleteChange}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  style={{ backgroundColor: 'rgba(70, 72, 104, 1)', color: "white", fontWeight: "500", borderRadius: "4px" }}
                  variant="filled"
                  label={option}
                  size="small"
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="Area di competenza"
                color="secondary"
              />
            )}
          />
        </FormControl>
        <FormControl
          variant="standard"
          sx={{ m: 1, minWidth: 350 }}
        >
          <TextField
            id="pec"
            label="PEC"
            variant="filled"
            color="secondary"
            value={pec}
            onChange={handleChangePec}
          />
        </FormControl>
        <Box>
          <FormControl
            variant="standard"
            sx={{ m: 1, flex: 1 }}
          >
            <FileUploadButton
              textButton="Carica Documento"
              onFileUpload={handleIdentityCardUpload}
              onRemoveFile={handleIdentityCardUploadRemoval}
            />
          </FormControl>
          <FormControl
            variant="standard"
            sx={{ m: 1, flex: 1 }}
          >
            <FileUploadButton
              textButton="Carica CV"
              onFileUpload={handleCvUpload}
              onRemoveFile={handleCvUploadRemoval} />
          </FormControl>
          <FormControl
            variant="standard"
            sx={{ m: 1, flex: 1 }}
          >

            <Button
              sx={{
                background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
                textTransform: 'none',
                fontSize: "14px",
                color: "white",
                fontWeight: 600,
                padding: "8px 16px",
                borderRadius: "8px",
                boxShadow: 'none',
                '&:hover, &:active, &:focus': {
                  boxShadow: 'none'
                }
              }}
              onClick={handleSubmit(onSubmit)}
              disabled={isPutNutritionistLoading}
            >
              {isPutNutritionistLoading ? (
                <CircularProgress color="secondary" />
              ) : (
                "Salva Modifiche"
              )}
            </Button>
          </FormControl>
        </Box>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1} >
        <Typography
          variant="h5"
          color="black"
          sx={{ fontWeight: "600", marginLeft: "8px" }}
        >
          I tuoi dati
        </Typography>
        <FormControl
          variant="standard"
          sx={{ m: 1, minWidth: 500 }}
        >
          <TextField
            id="nome"
            label="Nome"
            value={
              userChange.name != null ? userChange.name : ""
            }
            onChange={(event) =>
              setUserChange({ ...userChange, name: event.target.value })
            }
            variant="filled"
            color="secondary"
          />
        </FormControl>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 500 }}>
          <TextField
            id="cognome"
            label="Cognome"
            value={
              userChange.surname != null ? userChange.surname : ""
            }
            onChange={(event) =>
              setUserChange({ ...userChange, surname: event.target.value })
            }
            variant="filled"
            color="secondary"
          />
        </FormControl>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 500 }}>
          <TextField
            id="codiceFisc"
            label="Codice fiscale"
            variant="filled"
            color="secondary"
            value={
              userChange.tax_code != null ? userChange.tax_code : ""
            }
            onChange={(event) =>
              setUserChange({ ...userChange, tax_code: event.target.value })
            }
          />
        </FormControl>
        <FormControl
          variant="standard"
          sx={{ m: 1, minWidth: 500 }}
        >
          <TextField
            id="cellulare"
            label="Cellulare"
            variant="filled"
            color="secondary"
            type="tel"
            value={
              userChange.phone != null ? userChange.phone : ""
            }
            onChange={(event) =>
              setUserChange({ ...userChange, phone: event.target.value })
            }
          />
        </FormControl>
        <FormControl variant="filled" sx={{ m: 1, minWidth: 500 }}>
          <InputLabel id="sesso-select-label" color="secondary">
            Sesso
          </InputLabel>
          <Select
            labelId="sesso-select-label"
            id="selectSesso"
            label="Sesso"
            value={userChange.gender != null ? userChange.gender : ""}
            onChange={handleChangeSesso}
            color="secondary"
          >
            <MenuItem value={"male"}>Maschio</MenuItem>
            <MenuItem value={"female"}>Femmina</MenuItem>
            <MenuItem value={"non_binary"}>Non-Binary</MenuItem>
            <MenuItem value={""}>Preferisco non specificare</MenuItem>
          </Select>
        </FormControl>
        <Divider
          sx={{
            marginTop: "32px",
            width: "84%",
            marginLeft: "8px",
            bgcolor: "transparent", // Imposta il colore di sfondo su trasparente
            border: 0, // Rimuove il bordo predefinito
            borderTop: "1px dashed rgba(193, 199, 205, 0.50)", // Imposta il bordo superiore come tratteggiato
          }}
        />
        <Box>
          <Typography variant="h5" color="black" sx={{ fontWeight: "600", mt: 2, ml: 1 }}>
            Indirizzi dello studio
          </Typography>

          {userChange.addresses.map((address, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 500 }}>
                <TextField
                  label="Indirizzo"
                  variant="filled"
                  color="secondary"
                  value={address.street}
                  onChange={(event) => handleAddressInputChange(index, "street", event.target.value)}
                />
              </FormControl>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 240 }}>
                <TextField
                  label="Cap"
                  variant="filled"
                  color="secondary"
                  value={address.postal_code}
                  onChange={(event) => handleAddressInputChange(index, "postal_code", event.target.value)}
                />
              </FormControl>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 240 }}>
                <TextField
                  label="Città"
                  variant="filled"
                  color="secondary"
                  value={address.city}
                  onChange={(event) => handleAddressInputChange(index, "city", event.target.value)}
                />
              </FormControl>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 240 }}>
                <TextField
                  label="Nazione"
                  variant="filled"
                  color="secondary"
                  value={address.country}
                  onChange={(event) => handleAddressInputChange(index, "country", event.target.value)}
                />
              </FormControl>

              {userChange.addresses.length > 1 && (
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<Delete />}
                  onClick={() => handleRemoveAddress(index)}
                  sx={{ mt: 2, ml: 1 }}
                >
                  Rimuovi Indirizzo
                </Button>
              )}

              <Divider sx={{
                marginTop: "32px",
                width: "84%",
                marginLeft: "8px",
                bgcolor: "transparent", // Imposta il colore di sfondo su trasparente
                border: 0, // Rimuove il bordo predefinito
                borderTop: "1px dashed rgba(193, 199, 205, 0.50)", // Imposta il bordo superiore come tratteggiato
              }} />
            </Box>
          ))}

          <div className="gradient-border-wrapper" style={{ marginLeft: "8px" }}>
            <Button
              onClick={handleAddAddress}
              sx={{
                color: "linear-gradient(to top right, #B0D99C, #68BE6A)",
                fontWeight: 600, textTransform: 'none'
              }}
              className="gradient-button"
            >
              Aggiungi Indirizzo
            </Button>
          </div>
        </Box>
        <Divider
          sx={{
            marginTop: "32px",
            width: "84%",
            marginLeft: "8px",
            bgcolor: "transparent", // Imposta il colore di sfondo su trasparente
            border: 0, // Rimuove il bordo predefinito
            borderTop: "1px dashed rgba(193, 199, 205, 0.50)", // Imposta il bordo superiore come tratteggiato
          }}
        />
        <Typography
          variant="h5"
          color="black"
          sx={{ fontWeight: "600", marginTop: "10px", marginLeft: "8px" }}
        >
          Dati per l'accesso
        </Typography>
        <FormControl
          variant="standard"
          sx={{ m: 1, minWidth: 500 }}
        >
          <TextField
            type="email"
            id="email"
            label="Email"
            variant="filled"
            color="secondary"
            disabled="true"
            value={userChange.email != null ? userChange.email : ""}
            onChange={(event) =>
              setUserChange({ ...userChange, email: event.target.value })
            }
          />
        </FormControl>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 500 }}>
          <TextField
            disabled
            type="password"
            id="password"
            label="Password"
            defaultValue="************"
            variant="filled"
            color="secondary"
          />
        </FormControl>
        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <Box
              width="100%"
              height="5%"
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <CloseIcon
                sx={{ right: 0, cursor: "pointer" }}
                onClick={handleClose}
              />
            </Box>
            <Box
              width="100%"
              height="20%"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DoneIcon
                sx={{
                  border: "10px solid #B0D99C",
                  borderRadius: "50%",
                  bgcolor: "#B0D99C",
                  width: "48px",
                  height: "48px",
                  color: "white",
                }}
              />
            </Box>
            <Box
              width="100%"
              height="10%"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h5"
                color="black"
                sx={{ fontWeight: "bold", marginTop: "10px" }}
              >
                Modifica password
              </Typography>
            </Box>
            <Box
              width="100%"
              sx={{
                display: "flex",
                mt: "32px"
              }}
            >
              <InputLabel id="vecchia-password-label" color="secondary">
                Vecchia Password
              </InputLabel>
            </Box>
            <FormControl
              variant="standard"
              sx={{
                mt: 1,
                minWidth: 250,
                display: "flex",
              }}
            >
              <FilledInput
                onChange={handleChangeOldPass}
                color="secondary"
                id="vecchia-password-label"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Box
              width="100%"
              sx={{
                display: "flex",
                mt: "32px"
              }}
            >
              <InputLabel id="nuova-password-label" color="secondary">
                Nuova Password
              </InputLabel>
            </Box>
            <FormControl
              variant="standard"
              sx={{
                mt: 1,
                minWidth: 250,
                display: "flex",
              }}
            >
              <FilledInput
                onChange={handleChangeNewPass}
                color="secondary"
                id="nuova-password-label"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                error={!isPasswordValid}
              />
            </FormControl>
            {!isPasswordValid && <p style={{ color: "red" }}> La password deve essere almeno di 8 caratteri</p>}
            <Box
              width="100%"
              marginTop="50px"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography
                variant="h7"
                color="black"
                sx={{
                  fontWeight: "bold",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={handleClose}
              >
                Annulla
              </Typography>
              <Button
                sx={{
                  background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
                  textTransform: 'none',
                  fontWeight: 600,
                  boxShadow: 'none',
                  '&:hover, &:active, &:focus': {
                    boxShadow: 'none'
                  }
                }}
                variant="contained"
                size="medium"
                onClick={handleChangePassword}
              >
                Modifica la password
              </Button>
            </Box>
          </Box>
        </Modal>
        <FormControl
          variant="standard"
          sx={{ m: 1, minWidth: 380 }}
        >
          <Box sx={{ mt: "4px" }}>
            <FormControl variant="standard" sx={{ minWidth: 250 }}>
              <Box>
                <Button
                  className="gradient-button"
                  sx={{
                    textTransform: 'none',
                    fontWeight: 600,
                    mt: "2px",
                    ml: "-36px",
                    boxShadow: 'none',
                    '&:hover, &:active, &:focus': {
                      boxShadow: 'none'
                    }
                  }}
                  startIcon={<ModeEditIcon sx={{
                    color: "#B0D99C"

                  }} />}
                  onClick={handleOpen}
                >
                  Modifica Password
                </Button>
              </Box>
            </FormControl>
            <Button
              sx={{
                background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
                textTransform: 'none',
                marginLeft: "-72px",
                fontSize: "14px",
                color: "white",
                fontWeight: 600,
                padding: "8px 16px",
                borderRadius: "8px",
                boxShadow: 'none',
                '&:hover, &:active, &:focus': {
                  boxShadow: 'none'
                }
              }}
              onClick={handleSubmit(onSubmit)}
              disabled={isPutNutritionistLoading}
            >
              {isPutNutritionistLoading ? (
                <CircularProgress color="secondary" />
              ) : (
                "Salva Modifiche"
              )}
            </Button>
          </Box>
        </FormControl>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>


        <Subscription
          expirationDate={subscriptionInfo.subscription_plan_expiration}
          subscription_plan={subscriptionInfo.subscription_plan}
          handleSwitchTab={handleSwitchTab}
          showReferralDiscount={!!nutritionistHasReferredId}
        />

      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Typography variant="body1" marginLeft="8px" width="35%">
          Effettua il log-out dalla Nutriboard, per accedere nuovamente usa le tue credenziali.
        </Typography>
        <Box sx={{ marginLeft: "8px", marginTop: "10px" }}>
          <Button
            sx={{
              background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
              textTransform: 'none',
              fontWeight: 600,
              boxShadow: 'none',
              '&:hover, &:active, &:focus': {
                boxShadow: 'none'
              }
            }}
            variant="contained"
            size="medium"
            onClick={handleLogout}
          >
            Effettua il log out
          </Button>
        </Box>
        <Typography
          variant="h5"
          color="black"
          sx={{ fontWeight: "bold", marginLeft: "8px", marginTop: "60px" }}
        >
          Zona a rischio ⚠️
        </Typography>
        <Typography
          variant="body1"
          marginLeft="8px"
          width="35%"
          marginTop="10px"
        >
          Attenzione, questa sezione riguarda la cancellazione permanente del tuo account.
        </Typography>
        <Box sx={{ marginLeft: "8px", marginTop: "10px" }}>
          <Button color="error" variant="contained" size="medium" style={{ fontWeight: "600", borderRadius: "8px", boxShadow: "none" }}>
            Elimina l'account permanentemente
          </Button>
        </Box>
      </CustomTabPanel>
    </Box>
  );
}

export function ClientTable({ userInfo, clientName, clientSurname }) {
  const pythonApiUrl = process.env.REACT_APP_PYTHON_API_URL
  const navigate = useNavigate();
  const location = useLocation();
  const pathnameParts = location.pathname.split("/");
  const id = pathnameParts[pathnameParts.length - 1];
  const accessToken = useSelector((state) => state.auth.userToken);

  const [value, setValue] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
  const [anchorVisitList, setAnchorVisitList] = React.useState(null);
  const [isDownloadMealPlanLoading, setIsDownloadMealPlanLoading] = React.useState(false);
  const [lineChartLinesBd, setLineChartLinesBd] = React.useState({
    bd_dw: {
      color: "#464868",
      visibility: false,
    },
    bd_jp_7p: {
      color: "#3ECC89",
      visibility: false,
    },
    bd_jp_3p: {
      color: "#50aec6",
      visibility: false,
    },
    bd_thorland: {
      color: "#f0db79",
      visibility: false,
    },
    bd_lohman: {
      color: "#cd4f4f",
      visibility: false,
    },
    bd_katch: {
      color: "#e79178",
      visibility: false,
    },
    bd_sloan: {
      color: "#8884d8",
      visibility: false,
    },
    bd_forsyth: {
      color: "#ffffff",
      visibility: false,
    },
  })
  const { data: visitsData, isLoading: isVisitsLoading } =
    useGetVisitsQuery(id);
  const { data: mealPlansData, isLoading: isMealPlansLoading } =
    useGetMealPlansQuery(id);
  const [deleteVisit] =
    useDeleteVisitsMutation();
  const [deleteMealPlan] =
    useDeleteMealPlansMutation();
  const [
    putMealPlans,
  ] = usePutMealPlansMutation();
  const [postCloneMealPlans, { isLoading: isPostCloneMealPlansLoading }] = usePostCloneMealPlansMutation();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = () => {
    value === 0
      ? navigate(`/pazienti/addVisit/${id}`)
      : navigate(`/pazienti/pianoAlimentare/${id}`, { state: { clientId: id } });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedRow(null);
    setOpenConfirmDelete(false)
  };

  const handleOpenModal = (row) => {
    setOpenModal(true);
    setSelectedRow(row);
  };


  const handleModifyVisit = (id, clientId) => {
    setOpenModal(false);
    navigate(`/pazienti/editVisit/${id}`, { state: { clientId: clientId } });
  };

  const handleDeleteVisit = async (id) => {
    try {
      setOpenModal(false);
      await deleteVisit(id);
      window.location.reload();
    } catch (err) {
      console.error("Error occurred while deleting:", err);
    }
  };

  const handleModifyMealPlan = (mealPlanId, clientId) => {
    setOpenModal(false);
    navigate(`/pazienti/${clientId}/editPianoAlimentare/${mealPlanId}`, { state: { clientId: clientId, mealPlanId: mealPlanId } });
  };

  const handleCloneMealPlan = async (mealPlanId) => {
    try {
      setOpenModal(false);
      await postCloneMealPlans(mealPlanId);
      window.location.reload();
    } catch (err) {
      console.error("Error occurred while cloning meal plan:", err);
    }
  }
  const handleDownloadMealPlan = async (mealPlanId) => {
    try {
      setIsDownloadMealPlanLoading(true);
      const res = await axios.post(
        `${pythonApiUrl}/meal-plans/${mealPlanId}/pdf`,
        {
          name: clientName,
          surname: clientSurname,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          responseType: 'blob'
        }
      );

      if (res.status === 200) {
        console.info("Meal plan downloaded: ", mealPlanId)
        const url = window.URL.createObjectURL(res.data);

        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'meal_plan.pdf');
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

      }
    } catch (error) {
      setIsDownloadMealPlanLoading(false);
      console.error("An error occurred while downloading meal plan:", error.message);
    } finally {
      setIsDownloadMealPlanLoading(false);
      setOpenModal(false);
      setSelectedRow(null);
    }
  }
  const handleDeleteMealPlan = async (id) => {
    try {
      setOpenModal(false);
      await deleteMealPlan(id);
      window.location.reload();
    } catch (err) {
      console.error("Error occurred while deleting:", err);
    }
  };
  const computeAge = (birthDate) => {
    const currentDate = new Date();

    let age = currentDate.getFullYear() - birthDate.getFullYear();

    const currentMonth = currentDate.getMonth();
    const birthMonth = birthDate.getMonth();
    const currentDay = currentDate.getDate();
    const birthDay = birthDate.getDate();

    if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
      age--;
    }

    return age;
  }

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate().toString().padStart(2, '0'); // Get the day and pad it with leading zero if necessary
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month (zero-based) and pad it with leading zero if necessary
    const year = date.getFullYear(); // Get the full year

    return `${day}/${month}/${year}`;
  }
  const handleEditMealPlanVisit = async (mealPlanId, visitId) => {
    await putMealPlans({
      data: {
        visit_id: visitId,
      },
      id: mealPlanId,
    });
    setAnchorVisitList(null);
    window.location.reload();
  }


  const columns = [
    {
      field: "visit_date",
      headerName: "Data Visita",
      type: "date",
      flex: 1,
      headerClassName: "theme",
    },
    {
      field: "weight",
      headerName: "Peso",
      flex: 0.5,
      headerClassName: "theme",
    },
    {
      field: "bmi",
      headerName: "BMI",
      flex: 0.5,
      headerClassName: "theme",
    },
    {
      field: "eer",
      headerName: "BMR",
      flex: 0.5,
      headerClassName: "theme",
    },
    {
      field: "tdee",
      headerName: "TDEE",
      flex: 0.5,
      headerClassName: "theme",
    },
    {
      field: "id", headerName: "Antropometria", flex: 1, headerClassName: "theme",
      renderCell: (params) => (
        <Box sx={{ display: "flex", justifyContent: "flex-end", ml: "-10px" }}>
          <Button
            className="gradient-text-1"
            sx={{
              textTransform: 'none',
              fontWeight: 600,
              borderRadius: "8px",
              boxShadow: 'none',
              '&:hover, &:active, &:focus': {
                boxShadow: 'none'
              }
            }}

            onClick={() => navigate(`/pazienti/editVisit/${params.row.id}`, { state: { clientId: id, activeStep: 1 } })}
          >
            Visualizza antropometria
          </Button>
        </Box>
      )
    },
    {
      field: "meal_plans",
      headerName: "Piano Alimentare",
      flex: 1,
      headerClassName: "theme",
      renderCell: (params) => {
        const mealPlan = params.row.meal_plans;
        if (mealPlan && mealPlan.length > 0) {
          return <Box display="flex" gap="0.5rem" height="32px">
            {mealPlan.slice(0, 1).map((plan, index) => (
              <Box sx={{ display: "flex", justifyContent: "stretch", alignContent: "center", ml: "-10px" }}>
                <Button
                  onClick={() => navigate(`/pazienti/${id}/editPianoAlimentare/${plan.id}`, { state: { clientId: id, mealPlanId: plan.id } })}
                >
                  <Chip
                    key={`meal-plan-chip-${index}`}
                    style={{
                      backgroundColor: 'rgba(70, 72, 104, 1)',
                      color: "white",
                      fontWeight: "500",
                      borderRadius: "16px"
                    }}
                    label={plan.name}
                  />
                </Button>
              </Box>
            ))}
            {
              mealPlan.length > 1 &&
              <Chip
                label={`+${mealPlan.length - 1}`}
                sx={{ backgroundColor: "rgba(70, 72, 104, 1)", color: "white" }}
              />
            }
          </Box>
        } else {
          return (
            <Box sx={{ display: "flex", justifyContent: "flex-end", ml: "-8px" }}>
              <Button
                className="gradient-text-1"
                sx={{
                  textTransform: 'none',
                  fontWeight: 600,
                  borderRadius: "8px",
                  boxShadow: 'none',
                  '&:hover, &:active, &:focus': {
                    boxShadow: 'none'
                  }
                }}

                onClick={() => navigate(`/pazienti/pianoAlimentare/${id}`, { state: { clientId: id } })}
              >
                Nuovo piano alimentare
              </Button>
            </Box>
          );
        }

      },
    },
    {
      field: "options",
      headerName: "",
      width: 100,
      headerClassName: "theme",
      renderCell: (params) => {
        return <Box
        >
          <ListItemText primary={params.row.typology} />
          <ListItemIcon>
            <IconButton onClick={() => handleOpenModal(params.row)}>
              <MoreHorizIcon />
            </IconButton>
          </ListItemIcon>
        </Box>
      }
    },
  ];
  const getVisitDate = (mealPlanId) => {
    for (const item of visitsData.data) {
      if (item.meal_plans.length > 0) {
        for (const mealPlan of item.meal_plans) {
          const visitId = mealPlan.id;
          if (visitId === mealPlanId) {
            return item.visit_date;
          }
        }
      }
    }
    return null;
  };

  const columns2 = [
    {
      field: "name", headerName: "Nome", flex: 1, headerClassName: "theme",
      renderCell: (params) => (
        <Link
          style={{ color: "#2F1847", fontWeight: "bold", textDecoration: "none" }}
          to={`/pazienti/${id}/editPianoAlimentare/${params.row.id}`}
          state={{ clientId: id, mealPlanId: params.row.id }}
        >
          {params.value}
        </Link >
      ),
    },
    {
      field: "created_at",
      headerName: "Data",
      headerClassName: "theme",
      type: "date",
      flex: 1,
      valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
    },
    {
      field: "id",
      headerName: "Visita",
      headerClassName: "theme",
      flex: 1,
      renderCell: (params) => {
        const mealPlanId = params.value;
        const visitDate = getVisitDate(mealPlanId);

        if (visitDate) {
          return <span>{new Date(visitDate).toLocaleDateString()}</span>;
        } else {
          return (
            <Box sx={{ display: "flex", justifyContent: "flex-end", ml: "-16px"  }}>
              <Button className="gradient-text-1"
                sx={{

                  textTransform: 'none',
                  fontWeight: 600,
                  padding: "8px 16px",
                  borderRadius: "8px",
                  boxShadow: 'none',
                  '&:hover, &:active, &:focus': {
                    boxShadow: 'none'
                  }
                }}
                onClick={(event) => setAnchorVisitList({ mealPlanId: mealPlanId, anchorEl: event.currentTarget })}
              >
                Assegna ad una visita
              </Button>
              <Popover
                id="popover_id"
                open={Boolean(anchorVisitList) && anchorVisitList.mealPlanId === mealPlanId}
                anchorEl={anchorVisitList?.anchorEl}
                onClose={() => setAnchorVisitList(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                sx={{
                  borderRadius: "16px",
                  boxShadow: 'none',
                }}
              >
                <List>
                  {visitsData.data.map((visit, index) => (
                    <ListItem key={index}>
                      <ListItemText
                        primary={
                          <Button
                            sx={{ color: "#2F1847" }}
                            onClick={() => handleEditMealPlanVisit(mealPlanId, visit.id)}
                          >
                            {new Date(visit.visit_date).toLocaleDateString()}
                          </Button>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Popover>
            </Box>
          );
        }
      },
    },
    {
      field: "options",
      headerName: "",
      minWidth: 100,
      headerClassName: "theme",
      renderCell: (params) => {
        return <Box
        >
          <ListItemText primary={params.row.typology} />
          <ListItemIcon>
            <IconButton onClick={() => handleOpenModal(params.row)}>
              <MoreHorizIcon />
            </IconButton>
          </ListItemIcon>
        </Box>
      }
    },
  ];

  if (isVisitsLoading || isMealPlansLoading || isPostCloneMealPlansLoading || isDownloadMealPlanLoading) {
    return <div class="loader-container">
      <div class="loader"></div>
    </div>
      ;
  }

  if (visitsData === undefined && value === 0) {
    return <Button sx={{
      mr: "20px",
      background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
      textTransform: 'none',
      fontWeight: 600,
      padding: "8px 16px",
      borderRadius: "8px",
      boxShadow: 'none',
      '&:hover, &:active, &:focus': {
        boxShadow: 'none'
      }
    }} variant="contained" onClick={handleClick}>
      Aggiungi una nuova visita
    </Button>
  }

  if (mealPlansData.data.length === 0 && value === 1) {
    return <Button sx={{
      mr: "20px",
      background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
      textTransform: 'none',
      fontWeight: 600,
      padding: "8px 16px",
      borderRadius: "8px",
      boxShadow: 'none',
      '&:hover, &:active, &:focus': {
        boxShadow: 'none'
      }
    }} variant="contained" onClick={handleClick}>
      Aggiungi un nuovo piano alimentare
    </Button>
  }

  const rows = visitsData.data.map((item) => {
    const age = computeAge(new Date(userInfo.birth_date));
    const weight = item.measurement.weight;
    const height = item.measurement.height;
    const bmi = calculate_bmi(height, weight).toFixed(1);
    const eer = calculate_eer(userInfo.gender, height, weight, age).toFixed(0);
    const tdee = item.anamnesis.activities_level ? calculate_tdee(eer, item.anamnesis.activities_level.value).toFixed(1) : "ND";

    return {
      id: item.id,
      visit_date: new Date(item.visit_date),
      weight: weight,
      height: height,
      bmi: bmi,
      eer: eer,
      tdee: tdee,
      meal_plans: item.meal_plans,
    };
  });

  const weightData = rows.map((row) => ({
    visit_date: formatDate(new Date(row.visit_date)),
    weight: row.weight,
  }));

  const rows2 = mealPlansData.data.map((item) => ({
    id: item.id,
    name: item.name,
    created_at: item.created_at,
  }));

  return (
    <Box sx={{ width: "100%", marginTop: "12px", marginLeft: "-18px" }}>
      <Box  >
        <Box>
          <Box>
            <Toolbar sx={{ justifyContent: "space-between", ml: "-18px" }}>
              {/*LEFT SIDE*/}
              <StyledTabs
                value={value}
                onChange={handleChange}
                aria-label="styled tabs example"
              >
                <StyledTab label="Visite" {...a11yProps(0)} />
                <StyledTab label="Piano Alimentare" {...a11yProps(1)} />
              </StyledTabs>

              {/*RIGHT SIDE*/}
              <Box sx={{}}>
                <Button sx={{

                  background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
                  textTransform: 'none',
                  fontWeight: 600,
                  padding: "8px 16px",
                  borderRadius: "8px",
                  boxShadow: 'none',
                  '&:hover, &:active, &:focus': {
                    boxShadow: 'none'
                  }
                }} variant="contained" onClick={handleClick}>
                  {value === 0
                    ? "Aggiungi una nuova visita"
                    : "Aggiungi un nuovo piano alimentare"}
                </Button>
              </Box>
            </Toolbar>

          </Box>
          <CustomTabPanel value={value} index={0}>
            <Box mt="10px" ml="-18px">
              <DataGrid
                rows={rows.sort((a, b) => new Date(b.visit_date) - new Date(a.visit_date))}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                sx={{
                  "& .theme": {
                    backgroundColor: "rgba(70, 72, 104, 0.10)",
                    color: "#2F1847",
                  },
                }}
                hideFooterSelectedRowCount
                disableColumnMenu
                disableRowSelectionOnClick
              />
            </Box>
            {visitsData.data.filter(item => !!item.bia_measurement).length === 0 && visitsData.data.filter(item => !!item.skinfold_measurement).length === 0 &&
              <Box mt="40px" ml="-18px">
                <Box mb="20px">
                  <Typography color="#2F1847" fontSize="24px" fontWeight="700">
                    Andamento Peso
                  </Typography>
                </Box>
                <MeasurementSingleLineChart
                  data={weightData}
                  xAxisDataKey="visit_date"
                  yAxisLabelValue="Peso"
                  lineData={{ key: "weight", name: "Peso" }}
                  xAxisLabel="Data visita"
                  isXAxisDate={true}
                />
              </Box>}
            {visitsData.data.filter(item => !!item.bia_measurement).length > 0 && <BiaMetricsSection visitsData={visitsData} userInfo={userInfo} />}
            <Divider
              sx={{
                marginTop: "32px",
                marginLeft: "-5px",
                width: "100%",
                bgcolor: "transparent", // Imposta il colore di sfondo su trasparente
                border: 0, // Rimuove il bordo predefinito
                borderTop: "1px dashed rgba(193, 199, 205, 0.50)", // Imposta il bordo superiore come tratteggiato
              }}
            />
            {visitsData.data.filter(item => !!item.skinfold_measurement).length > 0 && <SkinfoldMetricsSection visitsData={visitsData} userInfo={userInfo} />}
            <Divider
              sx={{
                marginTop: "32px",
                marginLeft: "-5px",
                width: "100%",
                bgcolor: "transparent", // Imposta il colore di sfondo su trasparente
                border: 0, // Rimuove il bordo predefinito
                borderTop: "1px dashed rgba(193, 199, 205, 0.50)", // Imposta il bordo superiore come tratteggiato
              }}
            />
            {visitsData.data.filter(item => !!item.circumference_measurement).length > 0 && <CircumferenceMetricsSection visitsData={visitsData} />}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Box mt="10px" ml="-18px">
              <DataGrid
                rows={rows2}
                columns={columns2}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                sx={{
                  "& .theme": {
                    backgroundColor: "rgba(70, 72, 104, 0.10)",
                    color: "#2F1847",
                  },
                }}
                hideFooterSelectedRowCount
                disableColumnMenu
                disableRowSelectionOnClick
              />
            </Box>
          </CustomTabPanel>
        </Box>
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: 'fixed', // Usa fixed per posizionare la modale in relazione alla viewport
              top: '45%',       // Posiziona la parte superiore della modale a metà della viewport
              left: '50%',      // Posiziona il lato sinistro della modale a metà della viewport
              transform: 'translate(-50%, -50%)', // Sposta la modale indietro di metà della sua altezza e larghezza

            }}
          >
            {selectedRow && (
              <Box
                sx={{
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  position: "relative",
                }}
              >
                <Typography variant="h6" mb="8px">
                  {value === 0 ?
                    `Visita: ${selectedRow.visit_date.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}`
                    : `Piano alimentare: ${selectedRow.name}`
                  }
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', overflow: 'auto' }}>
                  {!openConfirmDelete && <Button
                    sx={{
                      marginRight: 2,
                      background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
                      textTransform: 'none',
                      borderRadius: "8px",
                      fontWeight: 600,
                      padding: "8px 16px",
                      color: "white",
                      boxShadow: 'none',
                      '&:hover': {
                        boxShadow: 'none'
                      }
                    }}
                    startIcon={<AddIcon />}
                    onClick={handleClick}
                  >
                    {value === 0 ? "Aggiungi visita" : "Aggiungi piano alimentare"}
                  </Button>}

                  {!openConfirmDelete && <div className="gradient-border-wrapper" style={{ marginRight: "16px" }}>
                    <Button
                      sx={{ marginRight: 2, fontWeight: "600", textTransform: "none", color: "#B0D99C" }}
                      startIcon={<EditIcon />}
                      className="gradient-button"
                      onClick={value === 0 ? () => handleModifyVisit(selectedRow.id, id) : () => handleModifyMealPlan(selectedRow.id, id)}
                    >
                      Modifica
                    </Button>
                  </div>}
                  {!openConfirmDelete && value === 1 && <div className="gradient-border-wrapper" style={{ marginRight: "16px" }}>
                    <Button
                      sx={{ marginRight: 2, fontWeight: "600", textTransform: "none", color: "#B0D99C" }}
                      startIcon={<ContentCopyIcon />}
                      className="gradient-button"
                      onClick={() => handleCloneMealPlan(selectedRow.id)}
                    >
                      Duplica
                    </Button>
                  </div>}
                  {!openConfirmDelete && value === 1 && <div className="gradient-border-wrapper" style={{ marginRight: "16px" }}>
                    <Button
                      sx={{ marginRight: 2, fontWeight: "600", textTransform: "none", color: "#B0D99C" }}
                      startIcon={<DownloadIcon />}
                      className="gradient-button"
                      onClick={() => handleDownloadMealPlan(selectedRow.id)}
                    >
                      Scarica
                    </Button>
                  </div>}
                  {!openConfirmDelete && <div className="gradient-border-wrapper-r" style={{ marginRight: "16px" }}>
                    <Button
                      sx={{ marginRight: 2, fontWeight: "600", textTransform: "none", color: "red" }}
                      className="red-button"
                      startIcon={openConfirmDelete ? <UndoIcon /> : <DeleteIcon />}
                      onClick={() => setOpenConfirmDelete(!openConfirmDelete)}
                    >
                      {openConfirmDelete ? "Annulla" : "Elimina"}
                    </Button>
                  </div>}
                </Box>
                <Box pt="16px">
                  <Collapse in={openConfirmDelete}>
                    <Alert
                      severity="warning"
                    >
                      <AlertTitle>Attenzione</AlertTitle>
                      {`Stai eliminando ${value === 0 ? "una visita" : "un piano alimentare"} — `}<strong>Sei sicuro?</strong>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end', // Allinea i bottoni a destra
                          marginTop: 2, // Aggiungi margine superiore se necessario
                          width: '100%', // Assicura che il Box occupi l'intera larghezza del contenitore padre
                        }}
                      >
                        <Button
                          color="inherit"
                          sx={{
                            textTransform: "none",
                            marginRight: 1 // Distanza tra i bottoni
                          }}
                          onClick={() => setOpenConfirmDelete(false)}
                        >
                          Annulla
                        </Button>
                        <Button
                          color="error"
                          sx={{
                            textTransform: "none"
                          }}
                          onClick={value === 0 ? () => handleDeleteVisit(selectedRow.id) : () => handleDeleteMealPlan(selectedRow.id)}
                        >
                          Elimina
                        </Button>
                      </Box>
                    </Alert>
                  </Collapse>
                </Box>

              </Box>
            )}
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}

const areaDiCompetenza = [
  { area: "Clinica" },
  { area: "Sportiva" },
  { area: "Pediatrica" },
  { area: "Geriatrica" },
  { area: "Oncologica" },
  { area: "Materno-infantile" },
  { area: "DCA" },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 600,
  bgcolor: "background.paper",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  p: 4,
  overflow: "hidden",
};
